import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract";
export const base_url = "/lineups";
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class Lineups {
  static get(params) {
    return abstract.get({ ...params });
  }

  static find(id, params) {
    return abstract.find(id, params);
  }

  static update(model) {
    return axios.post(
      url + "/" + model.id,
      helper.getFormData({ ...model, _method: "put" })
    );
  }

  static atualizar(model, atualizar) {
    return axios.post(url + "/" + model.id + "/atualizar", {
      atualizar: atualizar,
    });
  }

  static alteraData(model, campo) {
    return axios.post(url + "/" + model.id + "/data", {
      campo: campo,
      valor: model[campo],
    });
  }
  static create(model) {
    return axios.post(url, helper.getFormData(model));
  }

  static submit(model) {
    return abstract.submit(model, false); // qnd true é passado é para ser convertido em formData
  }

  static delete(id) {
    return abstract.delete(id);
  }

  static parametros(params) {
    return axios.get(url + "/parametros", { params: { ...params } });
  }

  static download(params) {
    return axios.post(
      url + "/download",
      { ...params },
      {
        // headers: {
        // 	'Content-Type': 'application/json',
        // 	'Accept': '*'
        // },
        responseType: "blob",
      }
    );
  }

  static importar(params) {
    return axios.post(url + "/importar", helper.getFormDataold({ ...params }));
  }

  static unidades() {
    return [
      { id: "t", name: "Tonelada" },
      { id: "m³", name: "Metro cúbico" },
    ];
  }

  static opcaoTipo() {
    return [
      { id: 0, name: "Lineup" },
      { id: 1, name: "Observação" },
    ];
  }

  static status() {
    return [
      { name: "Loading" },
      { name: "Discharging" },
      { name: "Waiting" },
      { name: "Expected" },
      { name: "Sailed" },
      { name: "POB hh:mm lt" },
    ];
  }

  static clientTypes() {
    return [
      { name: "-" },
      { name: "FULL" },
      { name: "HUSBANDRY" },
      { name: "PROTECTIVE AGENTS" },
      { name: "SURVEYOR" },
    ];
  }

  static saveList(list, terminal_id) {
    return axios.post(url + "/list", { list, terminal_id });
  }
}
