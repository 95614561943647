<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5"> </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Comm type</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-1">
                  Information of comm type
                </h6>

                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      required
                      label="Name of comm type"
                      placeholder="name of comm type"
                      input-classes="form-control-alternative"
                      :errors="errors.name"
                      v-model="model.name"
                    />
                  </div>
                </div>

                <h6 class="heading-small text-muted mb-2">
                  Comm of this type
                  <base-button
                    type="primary"
                    class="float-right"
                    icon
                    size="sm"
                    @click="addCarga()"
                  >
                    <i class="fas fa-plus mr-2"></i>
                    <span class="btn-inner--text">Add comm</span>
                  </base-button>
                  <div
                    class="text-danger invalid-feedback"
                    style="display: block"
                    v-for="erro in errors['cargas']"
                    v-if="errors['cargas']"
                  >
                    {{ erro }}
                  </div>
                </h6>

                <div class="row">
                  <div class="col-md-12">
                    <div class="card my-3">
                      <div class="card-header pb-0" style="border-bottom: none">
                        <div
                          class="form-row"
                          v-for="(carga, cargaIndex) in model.cargas"
                        >
                          <div class="col-lg-5">
                            <base-input
                              alternative=""
                              required
                              label="Comm"
                              placeholder="comm name"
                              input-classes="form-control-alternative"
                              :errors="errors['cargas.' + cargaIndex + '.name']"
                              v-model="carga.name"
                            />
                          </div>
                          <div class="col-lg-4">
                            <base-input
                              alternative=""
                              required
                              label="Synonym"
                              placeholder="synonym"
                              input-classes="form-control-alternative"
                              :errors="
                                errors['cargas.' + cargaIndex + '.synonym']
                              "
                              v-model="carga.synonym"
                            />
                          </div>
                          <div class="col-lg-1">
                            <base-input
                              alternative=""
                              label="Color"
                              placeholder="color"
                              input-classes="form-control-alternative"
                              type="color"
                              :errors="
                                errors['cargas.' + cargaIndex + '.color']
                              "
                              v-model="carga.color"
                            />
                          </div>
                          <div class="col-lg-1">
                            <div class="form-group has-label">
                              <label class="form-control-label">
                                To analyze
                              </label>
                              <br />
                              <label
                                class="custom-toggle mr-1 mt-2 text-center"
                              >
                                <input
                                  type="checkbox"
                                  @change="
                                    carga.ie_analise = carga.ie_analise ? 0 : 1
                                  "
                                  :checked="carga.ie_analise"
                                />
                                <span
                                  data-label-off="Off"
                                  data-label-on="On"
                                  class="custom-toggle-slider rounded-circle"
                                ></span>
                              </label>
                              <div
                                class="text-danger invalid-feedback"
                                style="display: block"
                                v-for="erro in errors[
                                  'cargas.' + cargaIndex + '.ie_analise'
                                ]"
                                v-if="
                                  errors['cargas.' + cargaIndex + '.ie_analise']
                                "
                              >
                                {{ erro }}
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-lg-1">
                            <div class="form-group has-label">
                              <label class="form-control-label"> Export </label>
                              <br />
                              <label class="custom-toggle mr-1 mt-2">
                                <input
                                  type="checkbox"
                                  @change="
                                    carga.ie_export = carga.ie_export ? 0 : 1
                                  "
                                  :checked="carga.ie_export"
                                />
                                <span
                                  data-label-off="Off"
                                  data-label-on="On"
                                  class="custom-toggle-slider rounded-circle"
                                ></span>
                              </label>
                              <div
                                class="text-danger invalid-feedback"
                                style="display: block"
                                v-for="erro in errors[
                                  'cargas.' + cargaIndex + '.ie_export'
                                ]"
                                v-if="
                                  errors['cargas.' + cargaIndex + '.ie_export']
                                "
                              >
                                {{ erro }}
                              </div>
                            </div>
                          </div> -->
                          <div class="col-lg-1">
                            <div class="form-group has-label">
                              <label
                                class="form-control-label"
                                style="color: transparent"
                              >
                                button
                              </label>
                              <button
                                type="submit"
                                class="btn base-button btn-danger w-100"
                                :disabled="loading"
                                @click="removeCarga(cargaIndex)"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>

            <div class="mt-4">
              <button
                type="submit"
                class="btn base-button btn-dark float-left"
                @click="$router.push('/cargas_tipos/listar')"
              >
                See listing
              </button>
              <button
                type="submit"
                class="btn base-button btn-primary float-right"
                :disabled="loading"
                @click="submit()"
              >
                Save comm
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/services/CargasTipos";
export default {
  name: "usuario_form",
  created() {
    if (this.$route.params.id) {
      this.model.id = this.$route.params.id;
      this.find();
    }
  },
  data() {
    return {
      errors: {},
      loading: false,
      Service,
      model: {
        name: "",
        cargas: [],
        id: null,
      },
    };
  },
  methods: {
    addCarga() {
      this.model.cargas.unshift({
        id: null,
        name: "",
        tipo_id: this.model.id,
        ie_export: 0,
        ie_import: 0,
        ie_analise: 0,
        color: "#000000",
        bercos: [],
      });
    },
    removeCarga(index) {
      this.model.cargas.splice(index, 1);
      this.$notify({
        type: "info",
        message:
          "Comm removed. This change will only be effective after saving the comm type",
        horizontalAlign: "center",
      });
    },

    find() {
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id, {
        with: "cargas",
      })
        .then((response) => {
          this.model = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      this.errors = {};
      Service.submit(this.model)
        .then((response) => {
          this.model = response.data;
          this.loading = false;
          this.$notify({
            type: "success",
            message: "Successfully saved comms!",
            horizontalAlign: "center",
          });
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the form",
              horizontalAlign: "center",
            });
          }
        });
    },
  },
};
</script>
<style></style>
