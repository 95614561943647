var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  { 'input-group': _vm.hasIcon },
  { 'form-group' : _vm.formGroup},
      // {'has-danger': errors.length},
      { focused: _vm.focused },
      { 'has-label': _vm.label || _vm.$slots.label } ]},[_vm._t("label",[(_vm.label)?_c('label',{staticClass:"form-control-label w-100",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]),(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend",on:{"click":function($event){return _vm.$emit('btn')}}},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonLeft",[_c('i',{class:_vm.addonLeftIcon})])],2)]):_vm._e(),_vm._t("default",[_c('multiselect',_vm._g(_vm._b({class:[
      { 'is-valid': _vm.valid === true },
      { 'is-invalid': _vm.valid === false },
      _vm.inputClasses ],attrs:{"options":_vm.options,"track-by":_vm.optionsKey,"label":_vm.optionsValue,"selectLabel":_vm.selectLabel,"selectedLabel":_vm.selectedLabel,"deselectLabel":_vm.deselectLabel,"placeholder":_vm.placeholder},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'multiselect',_vm.$attrs,false),_vm.listeners),[_c('template',{slot:"beforeList"},[[(_vm.options.length > 1 && Array.isArray(_vm.model) && _vm.model.length != _vm.options.length)?_c('li',{on:{"click":function($event){return _vm.addAll()}}},[_c('span',{staticClass:"multiselect__option btn-info",attrs:{"data-deselect":"Deselecionar"}},[_c('span',[_vm._v("ADICIONAR TODAS AS OPÇÕES")])])]):_vm._e(),(Array.isArray(_vm.model) && _vm.model.length > 1 && _vm.options.length > 0)?_c('li',{on:{"click":function($event){return _vm.removeAll()}}},[_c('span',{staticClass:"multiselect__option btn-danger",attrs:{"data-deselect":"Deselecionar"}},[_c('span',[_vm._v("REMOVER TODAS AS OPÇÕES")])])]):_vm._e()]],2),_c('template',{slot:"noOptions"},[_vm._v("No results found")]),_c('template',{slot:"noResult"},[_vm._v("No results found")])],2)],null,_vm.slotData),(_vm.addonRightIcon || _vm.$slots.addonRight)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonRight",[_c('i',{class:_vm.addonRightIcon})])],2)]):_vm._e(),_vm._t("infoBlock"),_vm._t("helpBlock",_vm._l((_vm.errors),function(erro){return (_vm.errors)?_c('div',{staticClass:"text-danger invalid-feedback",class:{ 'mt-2': _vm.hasIcon },staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(erro)+" ")]):_vm._e()}))],2)}
var staticRenderFns = []

export { render, staticRenderFns }