<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5"> </base-header>
    <div class="container-fluid mt-5">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                List <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
            <div class="col text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="openModalForm(null)"
              >
                <span class="btn-inner--text">New</span>
              </base-button>
            </div>
          </div>
          <!-- pesquisa -->
          <div class="row align-items-center mt-3">
            <div class="col-lg-6">
              <base-input
                alternative=""
                label="Name"
                placeholder="search by name"
                input-classes="form-control-alternative"
                v-model.lazy="search.name"
              />
            </div>
            <div class="col-lg-5">
              <base-input
                alternative=""
                label="Country"
                placeholder="search by country"
                input-classes="form-control-alternative"
                v-model.lazy="search.pais"
              />
            </div>

            <div class="col-lg-1">
              <div class="form-group has-label">
                <label class="form-control-label" style="color: transparent">
                  search</label
                >
                <button
                  type="submit"
                  class="btn base-button btn-primary w-100"
                  :disabled="loading"
                  @click="listar()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <!-- <button type="submit" class="btn base-button btn-primary w-100" @click="submit()"><i class="fa fa-check"></i></button> -->
            </div>
          </div>
          <!-- pesquisa -->
        </div>

        <div class="table-responsive" style="overflow: visible">
          <table
            class="table align-items-center table-flush tablesorter"
            tbody-classes="list"
            :data="list"
          >
            <thead class="thead-light">
              <tr>
                <th>Name</th>
                <th v-if="type == 'navios'">IMO</th>
                <th v-if="type == 'lineup_porto'">Country</th>
                <th></th>
              </tr>
            </thead>

            <tbody class="list">
              <tr v-for="(row, index) in list" :key="list.id">
                <td>
                  {{ row.name }}
                </td>
                <td v-if="type == 'navios'">
                  {{ row.imo }}
                </td>
                <td v-if="type == 'lineup_porto'">
                  {{ row.pais }}
                </td>

                <td class="text-right">
                  <base-dropdown class="dropdown" position="right">
                    <a
                      slot="title"
                      class="btn btn-sm btn-icon-only text-light"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>

                    <template>
                      <a class="dropdown-item" @click="openModalForm(row.id)"
                        ><i class="fas fa-user-edit"></i> edit</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="
                          $root.openExcluirModal(
                            row,
                            'Would you like to delete?',
                            Service
                          )
                        "
                        ><i class="fas fa-trash" style="min-width: 20px"></i>
                        delete</a
                      >
                    </template>
                  </base-dropdown>
                </td>
              </tr>
              <tr v-if="!list.length && !loading">
                <td colspan="100" style="text-align: center">No news found</td>
              </tr>
              <tr v-if="loading">
                <td colspan="100" style="text-align: center">
                  <i
                    class="fa fa-spinner fa-spin"
                    v-if="loading"
                    style="font-size: 20px"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer d-flex justify-content-end">
          <base-pagination
            :pagination="pagination"
            @change="listar()"
            align="center"
          ></base-pagination>
        </div>
      </card>
    </div>
    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
    <modal
      :show="modal.form.show"
      @close="
        () => {
          modal.form.show = false;
        }
      "
    >
      <template v-slot:header> Add new </template>
      <template>
        <generic-form
          v-if="modal.form.show"
          :id="modal.form.id"
          :service="Service"
          :type="type"
          @success="handleModalForm"
        />
      </template>
    </modal>
  </div>
</template>
<script>
import NaviosService from "@/services/Navios";
import AgenciasService from "@/services/Agencias";
import TrabalhadoresService from "@/services/Trabalhadores";
import LineupsPortosService from "@/services/LineupsPortos";
import AfretadoresService from "@/services/Afretadores";
import GenericForm from "./Form.vue";
export default {
  components: {
    "generic-form": GenericForm,
  },
  data() {
    return {
      Service: {},
      type: "",
      cargas: [],
      modal: {
        form: {
          show: false,
          id: null,
        },
      },
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1,
      },
      search: {
        name: "",
      },
      orderBy: {
        campo: "name",
        ordem: "asc",
      },
      list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
      ],
    };
  },
  created() {
    this.setGenericField();
  },

  watch: {
    // will fire on route changes
    //'$route.params.id': function(val, oldVal){ // Same
    "$route.path": function (val, oldVal) {
      this.setGenericField();
    },
  },
  methods: {
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false;
      if (updated) this.listar();
    },
    openModalForm(id) {
      this.modal.form.id = id;
      this.modal.form.show = true;
    },
    handleModalForm(model) {
      this.listar();
      this.modal.form.show = false;
    },
    setGenericField() {
      let campo = window.location.pathname.split("/").pop();
      this.type = campo;
      switch (campo) {
        case "navios":
        default:
          this.Service = NaviosService;
          break;
        case "afretadores":
          this.Service = AfretadoresService;
          break;
        case "trabalhadores":
          this.Service = TrabalhadoresService;
          break;
        case "agencias":
          this.Service = AgenciasService;
          break;
        case "lineup_porto":
          this.Service = LineupsPortosService;
          break;
      }
      // if(this.type=='lineup_porto'){
      //   this.orderBy.campo = 'pais'
      // }else{
      //   this.orderBy.campo = 'name'
      // }
      this.listar();
      console.log(campo);
    },
    listar() {
      this.loading = true;
      this.list = [];
      this.Service.get({
        ...this.pagination,
        ...this.search,
        ...this.orderBy,
      }).then((response) => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style></style>
