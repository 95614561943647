<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5"> </base-header>
    <div class="container-fluid mt-5">
      <!-- pesquisa -->
      <div class="card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col-lg-2">
              <h3 class="mb-0">
                List <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
            <div class="col text-right">
              <base-button type="info" icon size="sm" @click="cleanFiltros()">
                <i class="fas fa-filter mr-2"></i>
                <span class="btn-inner--text">Clear filters</span>
              </base-button>
              <base-button
                v-if="!$root.isClient"
                type="primary"
                icon
                size="sm"
                @click="openCreateForm({ id: null })"
              >
                <i class="fas fa-plus mr-2"></i>
                <span class="btn-inner--text">New lineup</span>
              </base-button>
            </div>
          </div>

          <div class="row align-items-center mt-3">
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Status"
                placeholder="Select the status"
                :options="Service.status()"
                optionsValue="name"
                optionsKey="name"
                v-model="search.status"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Charterers"
                placeholder="Select the charterers"
                :options="afretadores"
                optionsValue="name"
                v-model="search.afretador_id"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Disport"
                placeholder="select the destination port"
                :options="lineupPortos"
                optionsValue="port_name"
                v-model="search.destination_port"
              />
            </div>
            <div class="col-lg-3">
              <base-input
                type="date"
                alternative=""
                label="Start period"
                input-classes="form-control-alternative"
                v-model.lazy="search.periodo_inicio"
              />
            </div>
            <div class="col-lg-3">
              <base-input
                type="date"
                alternative=""
                label="End period"
                input-classes="form-control-alternative"
                v-model.lazy="search.periodo_fim"
              />
            </div>
            <div class="col-lg-3" v-if="!$root.isClient">
              <multiple-select
                multiple
                label="Naabsa vessels"
                placeholder="select naabsa vessels"
                optionsKey="name"
                :options="Service.clientTypes()"
                v-model="search.client_type"
              />
            </div>

            <div class="col-lg-3">
              <div class="form-group has-label float-right w-100">
                <label class="form-control-label" style="color: transparent">
                  search</label
                >
                <button
                  type="submit"
                  class="btn base-button btn-primary w-100"
                  :disabled="loading"
                  @click="listar()"
                >
                  <i class="fa fa-search"></i> search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- pesquisa -->
      <!-- nav portos -->
      <ul class="nav nav-tabs mt-2" id="ports-tabs">
        <li class="nav-item">
          <a class="nav-link disabled">PORTS:</a>
        </li>
        <li class="nav-item" v-for="porto in portosFiltro">
          <a
            class="nav-link"
            :class="[{ active: search.porto_id == porto.id }]"
            @click="setPortoSelecionado(porto)"
            >{{ porto.name }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="[{ active: !search.porto_id.length }]"
            @click="setPortoSelecionado({})"
            >ALL PORTS</a
          >
        </li>
      </ul>
      <!-- nav portos -->
      <!-- nav cargas -->
      <ul class="nav nav-tabs mt-2" id="comms-tabs">
        <li class="nav-item">
          <a class="nav-link disabled">COMMS:</a>
        </li>
        <li class="nav-item" v-for="carga in cargas">
          <a
            class="nav-link"
            :class="[{ active: search.carga_tipo_id == carga.id }]"
            @click="setCarga(carga)"
            >{{ carga.name }}</a
          >
        </li>
        <li class="nav-item" v-if="!$root.isClient">
          <a
            class="nav-link"
            :class="[{ active: !search.carga_tipo_id.length }]"
            @click="setCarga({})"
            >ALL COMMS</a
          >
        </li>
      </ul>
      <!-- nav cargas -->

      <div class="card my-3" footer-classes="pb-2" v-for="porto in portos">
        <div class="row justify-content-between mb-3">
          <div class="col-12 col-md-4">
            <table class="w-100 status-table">
              <tr class="bg-primary">
                <td colspan="3" class="text-center">
                  <h2 class="text-white">Vessels at {{ porto.name }} port</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>BERTHED</h3>
                </td>
                <td>
                  <h3>WAITING AT ANCHORAGE</h3>
                </td>
                <td>
                  <h3>EXPECTED TO CALL</h3>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <h3>
                    {{ getVesselsCountByStatus(porto, "Loading") }}
                  </h3>
                </td>
                <td class="text-center">
                  <h3>{{ getVesselsCountByStatus(porto, "Waiting") }}</h3>
                </td>
                <td class="text-center">
                  <h3>{{ getVesselsCountByStatus(porto, "Expected") }}</h3>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-12 col-md-8">
            <table class="w-100">
              <tr class="bg-primary">
                <td colspan="6" class="text-center">
                  <h2 class="text-white">Waiting Time(days)</h2>
                </td>
              </tr>
              <template v-for="chunck in chunckTerminais(porto.terminais)">
                <tr class="bg-primary text-white">
                  <td v-for="terminal in chunck">
                    {{ terminal.name }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="border: solid 1px black"
                    v-for="terminal in chunck"
                  >
                    {{ terminal.av_waiting_time }}
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
        <div class="card-header border-0 bg-primary">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-center text-white">
                {{ porto.name }}
                <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
          </div>
        </div>

        <div
          class="card-header border bordered"
          v-for="terminal in porto.terminais"
        >
          <div class="row mb-0 mx-0">
            <div
              class="col-6 border-bottom text-center"
              style="background-color: rgba(90, 101, 112, 0.5)"
            >
              <div class="badge text-dark">
                Av. Waiting time: {{ terminal.av_waiting_time || 0 }} days
              </div>
            </div>
            <div class="col-6 bg-white"></div>
            <div
              class="col-3 border-bottom border-right"
              style="background-color: rgba(90, 101, 112, 0.5)"
            >
              <div class="badge text-dark">
                A.V W/D: {{ terminal.av_dw_bw || 0 }}
              </div>
            </div>
            <div
              class="col-3 border-bottom"
              style="background-color: rgba(90, 101, 112, 0.5)"
            >
              <div class="badge text-dark">
                Loading rate: {{ terminal.rate || 0 }}
              </div>
            </div>
            <div class="col-6"></div>
            <div
              class="col-2 border-bottom border-right"
              style="background-color: rgba(90, 101, 112, 0.5)"
            >
              <div class="badge text-dark">
                Max draft: {{ terminal.max_draft || 0 }}m
              </div>
            </div>
            <div
              class="col-2 border-bottom border-right"
              style="background-color: rgba(90, 101, 112, 0.5)"
            >
              <div class="badge text-dark">
                Air berth: {{ terminal.max_draft || 0 }}m
              </div>
            </div>
            <div
              class="col-2 border-bottom"
              style="background-color: rgba(90, 101, 112, 0.5)"
            >
              <div class="badge text-dark">LOA: {{ terminal.loa || 0 }}m</div>
            </div>
          </div>

          <div class="row align-items-center my-2">
            <div class="col">
              <h3 class="mb-0">
                <div class="bagde badge-dark" style="padding-left: 10px">
                  {{ terminal.name }}
                  <base-button
                    v-if="!$root.isClient"
                    style="position: absolute; right: 15px; padding: 2px 6px"
                    type="primary"
                    icon
                    size="sm"
                    @click="
                      openCreateForm({
                        id: null,
                        porto_id: terminal.porto_id,
                        terminal_id: terminal.id,
                      })
                    "
                  >
                    <i class="fas fa-plus mr-2"></i>
                    <span class="btn-inner--text">New line</span>
                  </base-button>
                  <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                </div>
              </h3>
            </div>
          </div>
          <table_lineup
            @openModal="openCreateForm"
            @refresh="listar(true)"
            :carga="cargaSelecionada"
            :cargas="cargas.reduce((a, b) => a.concat(b.cargas), [])"
            :carga_tipo_id="search.carga_tipo_id[0]"
            :navios="navios"
            :afretadores="afretadores"
            :lineupPortos="lineupPortos"
            :agencias="agencias"
            :terminal="terminal"
            :lineups="
              list
                .filter((lineup) => lineup.terminal_id == terminal.id)
                .sort((a, b) => {
                  return (
                    new Date(a.etb || '1990-01-01') -
                    new Date(b.etb || '2090-01-01')
                  );
                })
            "
          ></table_lineup>
          <h5
            v-if="terminal.remark"
            class="mt-2 p-1"
            style="background-color: yellow"
          >
            {{ terminal.remark }}
          </h5>
        </div>
      </div>

      <div
        class="card my-3"
        footer-classes="pb-2"
        v-if="!portos.length && !loading"
      >
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-center">
                There is no port linked with this comm<i
                  class="fa fa-spinner fa-spin"
                  v-if="loading"
                ></i>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
    <modal
      :show="modal.form.show"
      @close="
        () => {
          modal.form.show = false;
          listar(true);
        }
      "
      modalClasses="modal-xl"
    >
      <template v-slot:header>
        <span style="color: transparent">Lineup</span>
      </template>
      <template>
        <lineup-form
          v-if="modal.form.show"
          :id="modal.form.id"
          :isModal="1"
          :premodel="modal.form.premodel"
          :withParams='true'
          :params="{
            navios:navios,
            portos:portosFiltro,
            agencias:agencias,
            cargasTipos:cargas,
            lineupPortos:lineupPortos,
            afretadores:afretadores
          }"
          
        />
      </template>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Service from "@/services/Lineups";
import PortosService from "@/services/Portos";
import CargasTiposService from "@/services/CargasTipos";
import TableLineup from "./Table.vue";
import FormLineup from "./Form.vue";
export default {
  components: {
    table_lineup: TableLineup,
    "lineup-form": FormLineup,
    Multiselect,
  },
  data() {
    return {
      Service,
      cargaSelecionada: {},
      portoSelecionado: {},
      cargas: [],
      navios: [],
      afretadores: [],
      terminais: [],
      agencias: [],
      bercos: [],
      terminal: {},
      lineupPortos: [],
      trabalhadores: [],
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1,
      },
      modal: {
        form: {
          show: false,
          id: null,
          premodel: {},
        },
      },
      list: [],
      portos: [],
      portosFiltro: [],
      search: {
        carga_tipo_id: [],
        porto_id: "",
        status: [
          "Loading",
          "Discharging",
          "Waiting",
          "Expected",
          "POB hh:mm lt",
        ],
        recebedores: [],
        embarcadores: [],
        agencia_id: [],
        navio_id: [],
        afretador_id: [],
        periodo_inicio: null,
        periodo_fim: null,
        destination_port: null,
        client_type: [],
      },
      defaultSearch: {},
      orderBy: {
        campo: "name",
        ordem: "asc",
      },
    };
  },
  created() {
    // this.search.periodo_inicio = moment().startOf("month").format("YYYY-MM-DD");
    // this.search.periodo_fim = moment().endOf("month").format("YYYY-MM-DD");
    this.defaultSearch = { ...this.search };
    PortosService.get({ with: "terminais.bercos",}).then((response) => {
      this.portosFiltro = response.data;
      this.portoSelecionado = this.portosFiltro[0];
      this.search.porto_id = [this.portoSelecionado.id];
      this.listarCargasTipos();
    });

    Service.parametros({
      navios: true,
      agencias: true,
      lineups_portos: true,
      afretadores: true,
      trabalhadores: true,
    }).then((response) => {
      this.navios = response.data.navios;
      this.lineupPortos = response.data.lineups_portos;
      this.agencias = response.data.agencias;
      this.afretadores = response.data.afretadores;
      this.trabalhadores = response.data.trabalhadores;
    });
  },
  watch: {
    // orderBy: {
    //   deep: true,
    //   handler() {
    //     this.pagination.page = 1;
    //     this.listar();
    //   },
    // },
    search: {
      deep: true,
      handler() {
        this.listar();
      },
    },
  },
  methods: {
    openCreateForm(lineup) {
      this.modal.form.show = true;
      this.modal.form.id = lineup.id;
      this.modal.form.premodel = lineup;
    },
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false;
      if (updated) this.listar();
    },
    download(aviso) {
      if (aviso) {
        if (
          !confirm("Realmente deseja baixar com as agências?", "Sim", "Não")
        ) {
          return false;
        }
      }
      this.$notify({
        type: "info",
        message: "Please wait for the download to start",
        horizontalAlign: "center",
      });

      Service.download({
        ...this.search,

        agencia: aviso ? 1 : 0,
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        const objectUrl = window.URL.createObjectURL(blob);

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = objectUrl;

        a.download = "lineups.xlsx";
        a.click();
        window.URL.revokeObjectURL(objectUrl);
      });
    },

    setCarga(carga) {
      if (carga) {
        // this.cargaSelecionada = carga;
        this.search.carga_tipo_id = carga?.id ? [carga.id] : [];
      }
      // this.listar();
    },
    setPortoSelecionado(porto) {
      if (porto) {
        this.portoSelecionado = porto;
        this.search.porto_id = porto?.id ? [porto.id] : [];
      }
      // this.listar();
    },
    cargaAtualizada(carga, atualizar) {
      this.loading = true;
      CargasTiposService.atualizar(carga, atualizar).then((response) => {
        carga.atualizado_data = response.data.atualizado_data;
        carga.atualizador = response.data.atualizador;
        this.$notify({
          type: "success",

          message: "Updated successfully!",
          horizontalAlign: "center",
        });
        this.loading = false;
      });
    },

    portoAtualizado(porto, carga, atualizar) {
      this.loading = true;
      PortosService.atualizar(porto, carga, atualizar).then((response) => {
        porto.carga_atualizada = response.data;
        this.$notify({
          type: "success",
          message: "Updated successfully!",
          horizontalAlign: "center",
        });
        this.loading = false;
      });
    },
    listarCargasTipos() {
      this.loading = true;
      this.cargas = [];
      CargasTiposService.get({
        with: ["cargas", "atualizador"],
        ...this.search,
      }).then((response) => {
        this.cargas = response.data;
        this.setCarga(this.cargas[0]);
        this.loading = false;
        // this.listar();
      });
    },
    listar(clear) {
      this.loading = true;

      this.listarPortos();
      // this.list = [];

      Service.get({
        ...this.search,
        with: [
          "carga",
          "cargaTipo",
          "terminal",
          "berco",
          "cargas",
          "afretadores",
          "cargas_afretadores",
          // "recebedores",
          "afretador",
          // "embarcadores",
          "agencia",
          "navio",
          // "importPorto",
          // "exportPorto",
          "destination_port",
        ],
      }).then((response) => {
        this.list = response.data;
        this.list.map((list) => Vue.set(list, "isInEdit", false));
        this.loading = false;
      });
    },
    cleanFiltros() {
      this.search = { ...this.defaultSearch };
      this.search.carga_tipo_id = this.cargaSelecionada.id
        ? [this.cargaSelecionada.id]
        : [];
      this.listar();
    },
    listarPortos() {
      this.loading = true;
      // this.portos = [];
      PortosService.get({
        id: this.search.porto_id,
        campo: "ordem",
        ordem: "asc",
        carga_tipo_id: this.search.carga_tipo_id,
        with: "terminais",

        // ...this.search
      }).then((response) => {
        this.portos = response.data;
        this.loading = false;
      });
    },
    getVesselsCountByStatus(porto, status) {
      const list = this.list.filter((l) => l.porto_id == porto.id);
      return list.filter((l) => l.status == status).length;
    },
    chunckTerminais(terminais = [], size = 6) {
      const chunck = [];
      for (let i = 0; i < terminais.length; i += size) {
        chunck.push(terminais.slice(i, i + size));
      }
      return chunck;
    },
  },
};
</script>
<style scoped="">
.cursor-pointer {
  cursor: pointer;
}
.form-group {
  margin-bottom: 0.5rem;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #525f7f;
  background-color: #f8f9fe;
  border-color: #123e76 #123e76 #fff;
}
.nav-tabs {
  border-bottom: 1px solid #123e76;
}
#ports-tabs li.nav-item a.nav-link,
#comms-tabs li.nav-item a.nav-link {
  cursor: pointer;
}
.status-table tr td {
  border: solid 1px black;
}
</style>
