import { render, staticRenderFns } from "./UserCard.vue?vue&type=template&id=07df5d12&"
import script from "./UserCard.vue?vue&type=script&lang=js&"
export * from "./UserCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports