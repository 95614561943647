<template>
  <div class="table-responsive" style="overflow-x: scroll; overflow: visible">
    <div class="d-flex w-100 justify-content-end mb-2" v-if="!$root.isClient">
      <button
      type="button"
      :class="`btn btn-sm btn-${edit ? 'danger' : 'primary'}`"
      @click="toggleEdit()"
      >
      <i :class="`fa fa-${edit ? 'ban' : 'edit'}`"></i>
      {{ edit ? "Cancel edit" : "Edit lines" }}
    </button>
  </div>
  <table
  class="table align-items-center table-flush tablesorter"
  tbody-classes="list"
  >
  <thead class="thead-light">
    <tr v-if="list.length">
      <th>Vessel</th>
      <!-- <th>Draft</th> -->
      <th>Status</th>
      <th style="max-width: 100px">QTTY</th>
      <th>Comm</th>
      <th>
        ETA
        <span class="float-right" v-if="edit"
        ><i
        title="Lock year?"
        class="fa"
        :class="lock_eta ? 'fa-lock' : 'fa-lock-open'"
        @click="lock_eta = lock_eta ? false : true"
        ></i
        ></span>
      </th>
      <th>
        ETB
        <span class="float-right" v-if="edit"
        ><i
        title="Lock year?"
        class="fa"
        :class="lock_etb ? 'fa-lock' : 'fa-lock-open'"
        @click="lock_etb = lock_etb ? false : true"
        ></i
        ></span>
      </th>
      <th>
        ETC
        <span class="float-right" v-if="edit"
        ><i
        title="Lock year?"
        class="fa"
        :class="lock_etc ? 'fa-lock' : 'fa-lock-open'"
        @click="lock_etc = lock_etc ? false : true"
        ></i
        ></span>
      </th>
      <th>Charter</th>
      <th>Disport</th>
      <th v-if="!$root.isClient">Agency</th>
      <th v-if="!$root.isClient">Naabsa client</th>
      <th></th>
    </tr>
  </thead>

  <tbody class="list">
    <template v-for="(row, index) in orderList">
      <tr
      :class="
      row.is_observacao && !row.isInEdit && !edit
      ? 'observation-row'
      : ''
      "
      :style="!$root.isClient ? getRowStyleByClientType(row) : ''"
      >
      <template v-if="row.is_observacao">
        <td colspan="5">
          <span v-if="!row.isInEdit && !edit">
            {{ row.observacao }}
          </span>
          <span v-else>
            <base-input
            :errors="errors[`list.${index}.observacao`]"
            v-model="row.observacao"
            />
          </span>
        </td>
      </template>
      <template v-else>
        <td>
          <span v-if="row.isInEdit == false && !edit">
            {{ row.navio.name }}
            <b v-if="row.call_number">
              *{{ row.call_number + (row.call_number > 1 ? "ND" : "ST") }}
              CALL
            </b>
          </span>
          <span v-else>
            <multiple-select
            optionsValue="name_with_imo"
            :disabled="!navios.length"
            :errors="errors[`list.${index}.navio_id`]"
            addonLeftIcon="fa fa-plus"
            inputClasses="form-control table-multiselect"
            :options="navios"
            selectLabel=""
            selectedLabel=""
            :formGroup="false"
              placeholder="select the vessel"
            deselectLabel=""
            @btn="
            () => {
              rowIndex = index;
              openModalCreate('navio');
            }
            "            
            v-model="row.navio_id"
            />
          </span>
        </td>
        <!-- <td>-</td> -->
        <td :class="getClass(row)">
          <span
          v-if="row.isInEdit == false && !edit"
          :style="getStatusStyle(row.status)"
          >
          {{ row.status.replace("hh:mm", row.pob) }}
        </span>
        <span v-else>
          <base-select
          :errors="errors[`list.${index}.status`]"
          placeholder="Select the status"
          options-key="name"
          :options="Service.status()"
          required
          v-model="row.status"
          @change="setStatus(row)"
          />
          <base-input
          v-if="row.status == 'POB hh:mm lt'"
          type="time"
          v-model="row.pob"
          />
        </span>
      </td>
      <td>
        <span v-if="row.isInEdit == false && !edit">
          {{ formatQty(row.qty) }}
        </span>
        <span v-else>
          <money
          class="form-control"
          v-model="row.qty"
          v-bind="$root.qtd"
          ></money>
          <div
          class="text-danger invalid-feedback"
          style="display: block"
          v-if="errors[`list.${index}.qty`]"
          v-for="erro in errors[`list.${index}.qty`]"
          >
          {{ erro }}
        </div>
      </span>
    </td>
    <td>
      <span
      v-if="!row.isInEdit && !edit"
      :style="`color: ${row.carga ? row.carga.color : '#000000'};`"
      >
      {{ row.carga && row.carga.name }}
    </span>
    <span v-else>
      <multiple-select
      optionsValue="name_with_synonym"
      :disabled="!cargas.length"
      :errors="errors[`list.${index}.carga_id`]"
      addonLeftIcon="fa fa-plus"
      required
      :options="
      cargas.filter((c) =>
        carga_tipo_id ? c.tipo_id == carga_tipo_id : true
        )
        "
        @btn="
        () => {
          rowIndex = index;
          openModalCreate('carga');
        }
        "
          placeholder="select the comm"
        inputClasses="form-control table-multiselect"            
        selectLabel=""
        selectedLabel=""
        :formGroup="false"
        deselectLabel=""
        @change="setCargaTipo(row)"
        v-model="row.carga_id"
        />
      </span>
    </td>
  </template>
  <td v-if="!row.is_observacao">
    <span v-if="row.isInEdit == false && !edit">
      {{ row.eta ? formatDate(row.eta) : "TBI" }}
    </span>
    <span v-else>
      <base-input-date
      :locked="lock_eta"
      :errors="errors[`list.${index}.eta`]"
      v-model="row.eta"
      />
    </span>
  </td>
  <td>
    <span v-if="row.isInEdit == false && !edit">
      {{ row.etb ? formatDate(row.etb) : "TBI" }}
    </span>
    <span v-else>
      <base-input-date
      :locked="lock_etb"
      :errors="errors[`list.${index}.etb`]"
      max="9999-12-31"
      placeholder="TBI"
      v-model="row.etb"
      :handleTab="
      () => {
        targetRow = row;
        focusTargetEtc();
      }
      "
      />
    </span>
  </td>
  <td>
    <span v-if="row.isInEdit == false && !edit">
      {{ row.etc ? formatDate(row.etc) : "TBI" }}
    </span>
    <span v-else :ref="targetRow == row ? 'currentRowEtc' : ''">
      <base-input-date
      :locked="lock_etc"
      :errors="errors[`list.${index}.etc`]"
      v-model="row.etc"
      max="9999-12-31"
      />
    </span>
  </td>
  <td>
    <span v-if="!row.isInEdit && !edit">
      {{
        row.is_observacao
        ? "-"
        : row.afretador
        ? row.afretador.name
        : ""
      }}
    </span>
    <span v-else-if="!row.is_observacao">
      <multiple-select
      :errors="errors[`list.${index}.afretador_id`]"
      :options="afretadores"
      optionsValue="name"
      addonLeftIcon="fa fa-plus"
      required
     inputClasses="form-control table-multiselect"            
      selectLabel=""
      selectedLabel=""
      :formGroup="false"
      placeholder="select the charter"
      deselectLabel=""
      v-model="row.afretador_id"
      @btn="
      () => {
        rowIndex = index;
        openModalCreate('afretador');
      }
      "
      />
    </span>
  </td>
  <td>
    <span v-if="!row.isInEdit && !edit">
      {{
        row.is_observacao
        ? "-"
        : row.destination_porto
        ? row.destination_port.name || row.destination_port.pais
        : ""
      }}
    </span>
    <span v-else-if="!row.is_observacao">
      <multiple-select
      :errors="errors[`list.${index}.destination_porto`]"
      :options="lineupPortos"
      optionsValue="port_name"
      addonLeftIcon="fa fa-plus"
      required
        placeholder="select the disport"
      @btn="
      () => {
        rowIndex = index;
        openModalCreate('lineup_porto', 'destination_porto');
      }
      "
        inputClasses="form-control table-multiselect"            
      selectLabel=""
      selectedLabel=""
      :formGroup="false"
      deselectLabel=""
      v-model="row.destination_porto"
      />
    </span>
  </td>
  <td v-if="!$root.isClient">
    <span v-if="!row.isInEdit && !edit">
      {{ row.agencia && row.agencia.name }}
    </span>
    <span v-else-if="!row.is_observacao">
      <multiple-select
      :disabled="!agencias.length"
      :errors="errors[`list.${index}.agencia_id`]"
      placeholder="Select the agency"
      :options="agencias"
      addonLeftIcon="fa fa-plus"
      inputClasses="form-control table-multiselect"            
      selectLabel=""
      selectedLabel=""
      :formGroup="false"
      deselectLabel=""
      v-model="row.agencia_id"

      ref="agencias"
      @btn="
      () => {
        rowIndex = index;
        openModalCreate('agencia');
      }
      "
      />
    </span>
  </td>
  <td v-if="!$root.isClient">
    <span v-if="!row.isInEdit && !edit">
      {{ row.client_type }}
    </span>
    <span v-else-if="!row.is_observacao">
      <base-select
      :errors="errors[`list.${index}.client_type`]"
      placeholder="Select the client type"
      options-key="name"
      :options="Service.clientTypes()"
      v-model="row.client_type"
      />
    </span>
  </td>
  <td class="text-right">
    <button
    v-if="row.isInEdit && !rowloading && !edit"
    @click="saveRow(row)"
    class="btn btn-primary p-1 mt-1"
    >
    save
  </button>
  <button
  v-if="row.isInEdit && !rowloading && !edit"
  class="btn btn-secondary p-1"
  @click="cancelEditLine(row)"
  >
  cancel
</button>
<div class="btn-group">
  <button
  v-if="edit"
  class="btn btn-sm btn-danger"
  @click="deleteLine(row)"
  >
  <i class="fas fa-trash"></i>
</button>

<button
v-if="
edit &&
!row.is_observacao &&
row.cargas_afretadores &&
row.cargas_afretadores.length == 0
"
class="ml-1 btn btn-sm btn-primary"
@click="addCargaAfretador(row)"
>
<i class="fas fa-plus"></i>
</button>
</div>
<i
class="fa fa-spinner fa-spin"
v-if="rowloading"
style="font-size: 20px"
></i>
<base-dropdown
class="dropdown"
position="right"
v-if="!$root.isClient && !inEdit && !edit"
>
<a
slot="title"
class="btn btn-sm btn-icon-only text-light"
role="button"
data-toggle="dropdown"
aria-haspopup="true"
aria-expanded="false"
style="height: 23px"
>
<i class="fas fa-ellipsis-v"></i>
</a>

<template>
  <a
  class="dropdown-item"
  @click="setEditLine(row)"
  v-if="row.status != 'Sailed' && !edit"
  ><i class="fas fa-edit"></i> edit in line
</a>
<a class="dropdown-item" @click="$emit('openModal', row)">
  <i class="fas fa-edit"></i> edit in modal
</a>
<a
v-if="!row.is_observacao"
class="dropdown-item"
@click="
$emit('openModal', {
  call_in_id: row.id,
  navio_id: row.navio_id,
})
"
>
<i class="fas fa-plus"></i> add call
</a>
<a
class="dropdown-item"
@click="
$root.openExcluirModal(
  row,
  'Would you like to delete the lineup?',
  Service
  )
  "
  ><i class="fas fa-trash" style="min-width: 20px"></i>
  remove</a
  >
</template>
</base-dropdown>
</td>
</tr>
<tr v-for="(carga_afretador, i) in row.cargas_afretadores">
  <td colspan="3"></td>
  <td>
    <span v-if="!edit">
      {{ formatQty(carga_afretador.qty) }}
    </span>
    <span v-else>
      <money
      class="form-control"
      v-model="carga_afretador.qty"
      v-bind="$root.qtd"
      ></money>
      <div
      class="text-danger invalid-feedback"
      style="display: block"
      v-if="errors[`list.${index}.cargas_afretadores.${i}.qty`]"
      v-for="erro in errors[
      `list.${index}.cargas_afretadores.${i}.qty`
      ]"
      >
      {{ erro }}
    </div>
  </span>
</td>
<td>
  <span v-if="!edit">
    {{ getValue("cargas", carga_afretador.carga_id) }}
  </span>
  <span v-else>
    <base-select
    :errors="
    errors[`list.${index}.cargas_afretadores.${i}.carga_id`]
    "
    :options="cargas"
    optionsValue="name"
    required
    v-model="carga_afretador.carga_id"
    />
  </span>
</td>
<td colspan="2"></td>
<td>
  <span v-if="!edit">
    {{ getValue("afretadores", carga_afretador.afretador_id) }}
  </span>
  <span v-else>
    <base-select
    :errors="
    errors[`list.${index}.cargas_afretadores.${i}.afretador_id`]
    "
    :options="afretadores"
    optionsValue="name"
    required
    v-model="carga_afretador.afretador_id"
    />
  </span>
</td>
<td colspan="2"></td>
<td style="text-align: right" colspan="2">
  <div class="btn-group">
    <button
    v-if="edit && !$root.isClient"
    class="btn btn-sm btn-danger"
    @click="removeCargaAfretador(row, carga_afretador)"
    >
    <i class="fas fa-trash"></i>
  </button>
  <button
  class="ml-1 btn btn-sm btn-primary"
  v-if="
  edit &&
  row.cargas_afretadores.length == i + 1 &&
  !$root.isClient
  "
  @click="addCargaAfretador(row)"
  >
  <i class="fas fa-plus"></i>
</button>
</div>
</td>
</tr>
</template>
<tr v-if="!list.length && !loading">
  <td colspan="100" style="text-align: center">Nenhuma lineup</td>
</tr>
<tr v-if="loading">
  <td colspan="100" style="text-align: center">
    <i
    class="fa fa-spinner fa-spin"
    v-if="loading"
    style="font-size: 20px"
    ></i>
  </td>
</tr>
</tbody>
</table>
<div class="d-flex w-100 justify-content-between mt-3" v-if="edit">
  <div>
    <button class="btn btn-sm btn-primary" @click="addLine()">
      <i class="fa fa-plus"></i>
    </button>
    <button
    class="btn btn-sm btn-primary"
    @click="
    () => {
      list.push({
        terminal_id: terminal.id,
        porto_id: terminal.porto_id,
        status: 'Expected',
        is_observacao: 1,
        observacao: null,
        cargas_afretadores: [],
        etb: null,
        eta: null,
        etc: null,
        navio_id: null,
        afretador_id: null,
      });
    }
    "
    >
    Add note
  </button>
</div>
<button
class="btn btn-sm btn-primary"
@click="saveList()"
:disabled="rowloading"
>
<i
class="fa fa-spinner fa-spin"
v-if="rowloading"
style="font-size: 20px"
></i>
<i v-else>Save</i>
</button>
</div>
<modal
:show="modal.create.show"
@close="
() => {
  modal.create.show = false;
}
"
>
<template v-slot:header> Add new </template>
<template>
  <generic-form
  v-if="modal.create.show"
  :service="modal.create.service"
  :type="modal.create.type"
  :params="modal.create.params"
  @success="handleModalForm"
  />
</template>
</modal>
</div>
</template>
<script>
  import Service from "@/services/Lineups";
  import LineupsPortosService from "@/services/LineupsPortos";
  import PortosService from "@/services/Portos";
  import NaviosService from "@/services/Navios";
  import TrabalhadoresService from "@/services/Trabalhadores";
  import AgenciasService from "@/services/Agencias";
  import AfretadoresService from "@/services/Afretadores";
  import CargasTiposService from "@/services/CargasTipos";
  import CargasService from "@/services/Cargas";
  import GenericForm from "./../Generic/Form.vue";
  import { Money } from "v-money";
  import moment from "moment";

  export default {
    props: {
      lineups: {
        default: () => [],
      },
      carga_tipo_id: {
        default: null,
      },
      carga: {
        default: () => {},
      },
      navios: {
        default: () => [],
      },
      cargas: {
        default: () => [],
      },
      afretadores: {
        default: () => [],
      },
      lineupPortos: {
        default: () => [],
      },
      agencias: {
        default: () => [],
      },
      terminal: {
        default: () => ({}),
      },
    },
    components: {
      Money,
      "generic-form": GenericForm,
    },
    computed: {
      inEdit() {
        return this.list.filter((lineups) => lineups.isInEdit == true).length;
      },
      orderList() {
        return _.orderBy(this.list, ["etb"], ["asc"]);
      },
    },
    data() {
      return {
        lock_eta: true,
        lock_etb: true,
        lock_etc: true,
        rowIndex: null,
        Service,
        loading: false,
        editRow: {},
        edit: false,
        rowloading: false,
        search: {
          terminal_id: null,
          carga_tipo_id: null,
        },
        errors: {},
        orderBy: {
          campo: "etb",
          ordem: "asc",
        },
        list: [],
        modal: {
          create: {
            show: false,
            service: {},
            type: null,
            params: {},
            model: null,
            index: null,
          },
          confirm: {
            show: false,
          },
        },
        targetRow: null,
      };
    },
    created() {
      if (this.lineups) {
        this.list = this.lineups;
      } else {
        this.listar();
      }
    },
    watch: {
      lineups: {
        deep: true,
        handler() {
          this.list = this.lineups;
        },
      },
      search: {
        deep: true,
        handler() {
          this.listar();
        },
      },
      orderBy: {
        deep: true,
        handler() {
          this.pagination.page = 1;
          this.listar();
        },
      },
    },
    methods: {
      addLine() {
        this.list.push({
          navio_id: null,
          status: null,
          qty: null,
          carga_id: null,
          carga_tipo_id: this.carga_tipo_id,
          eta: null,
          etb: null,
          etc: null,
          afretador_id: null,
          destination_porto: null,
          agencia_id: null,
          porto_id: this.terminal.porto_id,
          terminal_id: this.terminal.id,
          cargas_afretadores: [],
          isInEdit: true,
          is_observacao: 0,
        });
      },
      getRowStyleByClientType(row) {
        if (row.is_observacao) return;
        const style = "background-color:";
        switch (row.client_type) {
          case "HUSBANDRY":
          return style + "rgb(220 255 220)";
          case "FULL":
          return style + "#d6e9ff";
          case "PROTECTIVE AGENTS":
          return style + "#fbe5bd";
        }
      },
      formatDate(value) {
        const date = new Date(value);
        const day = date.getDate() + 1;
        const month = date.toLocaleString("en-US", { month: "short" });
        const year = date.getFullYear();
        const currentYear = new Date().getFullYear();
        return `${day} - ${month} ${year != currentYear ? year : ""}`;
      },
      setEditLine(row) {
        this.editRow = { ...row };
        row.isInEdit = true;
      },
      cancelEditLine(row) {
        this.$helper.set(row, this.editRow, Object.keys(row));
        console.log(row);
        row.isInEdit = false;
      },
      saveRow(row) {
        this.rowloading = true;
        this.errors = {};
        Service.submit(row)
        .then((response) => {
          this.$helper.set(row, response.data, Object.keys(row));
          row.isInEdit = false;
          this.rowloading = false;
          this.$emit("refresh");
          this.$notify({
            type: "success",
            message: "Lineup saved successfully!",
            horizontalAlign: "center",
          });
        })
        .catch((err) => {
          this.rowloading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the lineup",
              horizontalAlign: "center",
            });
          }
        });
      },
      getClass(row) {
        if (row.status != "Expected") return;
        const eta = new Date(row.eta).getTime();
        const now = new Date().getTime();
        return eta <= now ? "text-warning" : "";
      },
      handleExcluir(updated) {
        this.$root.modal.excluir.isOpen = false;
        if (updated) this.$parent.listar();
      },
      marcarAtualizado(lineup, atualizar) {
        this.loading = true;
        Service.atualizar(lineup, atualizar).then((response) => {
          lineup.atualizado_data = response.data.atualizado_data;
          this.$notify({
            type: "success",
            message: "Atualizado com sucesso!",
            horizontalAlign: "center",
          });
          this.loading = false;
        });
      },
      alteraData(lineup, campo) {
        Service.alteraData(lineup, campo).then((response) => {
          lineup[campo] = response.data[campo];
          lineup.espera = response.data.espera;
        });
      },
      listar() {
        this.loading = true;
        this.list = [];
        Service.get({
          ...this.search,
          with: [
          "carga",
          "cargaTipo",
          "terminal",
          "berco",
          "afretador",
          "agencia",
          "navio",
          "cargas_afretadores",
          ],
        }).then((response) => {
          this.list = response.data;
          this.loading = false;
        });
      },
      toggleEdit(value = null) {
        const isInEdit = value !== null ? value : !this.edit;
        if (!isInEdit) {
          this.list = this.list
          .map((l) => {
            l.cargas_afretadores = l.cargas_afretadores.filter((c) => c.id);
            return l;
          })
          .filter((l) => l.id);
        }
        this.list = this.list.map((l) => ({ ...l, isInEdit }));
        this.edit = isInEdit;
      },
      openModalCreate(type, model, index) {
        switch (type) {
          case "agencia":
          this.modal.create.service = AgenciasService;
          this.modal.create.params = {};
          break;

          case "navio":
          this.modal.create.service = NaviosService;
          this.modal.create.params = {};
          break;

          case "trabalhador":
          this.modal.create.service = TrabalhadoresService;
          this.modal.create.params = {};
          this.modal.create.model = model;
          this.modal.create.index = index;
          break;

          case "afretador":
          this.modal.create.service = AfretadoresService;
          this.modal.create.params = {};

          break;
          case "lineup_porto":
          this.modal.create.service = LineupsPortosService;
          this.modal.create.params = {};
          this.modal.create.model = model;

          break;

          case "carga":
          if (!this.carga_tipo_id) {
            this.$notify({
              type: "danger",
              message: "Please select the comm type",
              horizontalAlign: "center",
            });
            return false;
          }
          this.modal.create.service = CargasService;
          this.modal.create.params = { tipo_id: this.carga_tipo_id };
          break;
        }

        this.modal.create.type = type;
        this.modal.create.show = true;
      },
      handleModalForm(model) {
        switch (this.modal.create.type) {
          case "agencia":
          this.list[this.rowIndex].agencia_id = model.id;
          this.agencias[this.agencias.length] = model;
          break;
          case "navio":
          this.list[this.rowIndex].navio_id = model.id;
          this.navios[this.navios.length] = model;
          break;
          case "lineup_porto":
          this.list[this.rowIndex].destination_porto = model.id;
          this.lineupPortos[this.lineupPortos.length] = model;
          break;
          case "afretador":
          this.list[this.rowIndex].afretador_id = model.id;
          this.afretadores[this.afretadores.length] = model;
          break;
          case "carga":
          this.list[this.rowIndex].carga_id = model.id;
          this.cargas[this.cargas.length] = model;
          break;
        }
        this.modal.create.type = null;
        this.modal.create.show = false;
      },
      getValue(modelName, modelId, key = "name") {
        return this[modelName].find((m) => m.id == modelId)[key];
      },
      checkValue(modelName, modelId, key = "name", result = "TBI") {
        if (!modelId) return true;
        return this[modelName].find((m) => m.id == modelId)[key] === result;
      },
      setStatus(row) {
        if (row.status !== "Sailed") return;
        let haveTBI = false;
        if (this.checkValue("navios", row.navio_id)) haveTBI = true;
        if (this.checkValue("cargas", row.carga_id)) haveTBI = true;
        if (this.checkValue("afretadores", row.afretador_id)) haveTBI = true;
        if (this.checkValue("lineupPortos", row.destination_porto, "pais"))
          haveTBI = true;
        if (this.checkValue("agencias", row.agencia_id)) haveTBI = true;
        if (haveTBI) {
          row.status = null;
          return this.$notify({
            type: "danger",
            message: "Please inform all fields",
            horizontalAlign: "center",
          });
        }
        if (this.checkValue("agencias", row.agencia_id, "name", "NAABSA")) {
          if (!confirm("All fields are correct ?")) row.status = null;
        }
      },
      setCargaTipo(model) {
        if (model.carga_tipo_id) return;
        model.carga_tipo_id = this.cargas.find(
          (c) => c.id == model.carga_id
          ).tipo_id;
      },
      async saveList() {
        this.errors = {};
        this.rowloading = true;
        try {
          await Service.saveList(this.list, this.terminal.id);
          this.$emit("refresh");
          this.toggleEdit();
          this.$notify({
            type: "success",
            message: "Lines saved successfully!",
            horizontalAlign: "center",
          });
        } catch (err) {
          this.errors = err.response.data.errors;
        }
        this.rowloading = false;
      },
      deleteLine(row) {
        if (row.id) {
          return this.$root.openExcluirModal(
            row,
            "Would you like to delete the lineup?",
            Service
            );
        }
        this.list = this.list.filter((l) => l != row);
      },
      addCargaAfretador(row) {
        row.cargas_afretadores.push({
          carga_id: null,
          qty: null,
          afretador_id: null,
        });
      },
      removeCargaAfretador(row, carga_afretador) {
        row.cargas_afretadores = row.cargas_afretadores.filter(
          (c) => c != carga_afretador
          );
      },
      formatQty(qty = 0) {
        qty = typeof qty == "number" ? qty : Number(qty);
        return qty.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
      },
      getStatusStyle(style) {
        switch (style) {
          case "Loading":
          return "font-style: italic;";
          case "Waiting":
          return "font-weight: lighter;";
          case "Expected":
          return "font-weight: bold;";
        }
      },
      sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      },
      focusTargetEtc() {
        this.sleep(50).then(() => {
          if (this.$refs.currentRowEtc) {
            this.$refs.currentRowEtc[0].querySelector("input").focus();
            this.targetRow = null;
          }
        });
      },
    },
  };
</script>
<style scoped>


.card .table td,
.card .table th {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.table td,
.table th {
  font-size: 0.7125rem;
  white-space: normal;
  padding: 0.1rem 1rem;
}

.hover-style {
  padding: 0;
  margin: 0;
  height: auto;
  font-size: 0.7125rem;
  border: none;
  width: 5.7125rem;
}

.observation-row {
  background-color: grey !important;
  color: white !important;
}

/*.hover-style:focus,.hover-style:hover{
  border:1px solid #cad1d7;
  padding: 0.625rem 0.75rem;
  width: 6.6125rem;


  }*/
/*input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    }*/
    tr.no-border td {
      border: none;
    }

    tr.with-border td {
      border-top: none;
      border-bottom: solid 1px #e9ecef;
    }
    td.no-text {
      color: transparent;
    }
  </style>
  <style type="">
  
  .table-multiselect{
    font-size: 0.7rem !important;
    /*margin-bottom: 0px;*/
    white-space: nowrap;
  }
  .table-multiselect .multiselect__single{
    font-size: 0.7rem !important;
    /*margin-bottom: 0px;*/
    overflow: hidden;
    white-space: nowrap;
  }
  .table-multiselect  .multiselect__option{
    white-space: normal;

  }
  .table-multiselect  .multiselect__option{
    white-space: normal;

  }
  .table-multiselect   .multiselect__select {
    padding: 1px 2px 0 1px !important;
        width: 20px;
    height: 33px;
    display: none
    /* padding: 0; */
  }

  .table-multiselect .multiselect__tags {
    height: calc(1.5em + 1.25rem + 0px) !important;
    padding: 11px 10px 0 0px !important;
  }


  .table-multiselect.multiselect{
    /*  border: 1px solid #cad1d7;*/
  }
</style>
