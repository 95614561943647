<template>
  <modal :show="show" @close="close()">
    <template v-slot:header> Delete </template>
    <template>
      {{ mensagem }}
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn base-button btn-primary"
        @click="excluir()"
      >
        Excluir
      </button>
      <button
        type="button"
        class="btn base-button ml-auto btn-link"
        @click="close()"
      >
        Cancelar
      </button>
    </template>
  </modal>
  <!--     <div
        class="modal fade"
        :id="'excluirModal'"
        tabindex="-1"
        role="dialog"
        aria-labelledby="excluirModal"
        aria-hidden="false"
    >
        <a ref="abrir" data-toggle="modal" data-target="#excluirModal"></a>
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6
                        class="modal-title font-weight-normal"
                        id="exampleModalLabel"
                    >
                        Exclusão
                    </h6>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">                    
                    {{ mensagem }}
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn bg-gradient-secondary"
                        @click="close()"
                    >
                        Cancelar
                    </button>
                    <button
                        type="button"
                        class="btn bg-gradient-primary"
                        @click="excluir()"
                    >
                        Excluir
                    </button>
                </div>
            </div>
        </div>
    </div> -->
</template>
<script>
export default {
  name: "excluir-modal",
  data() {
    return {};
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Function,
      default: () => {},
    },
    mensagem: {
      type: String,
      default: "você tem certeza que deseja excluir?",
    },
    errors: {
      type: Array,
    },
  },
  mounted() {
    // $("#excluirModal").modal("show");

    this.triggerHidden();
  },
  methods: {
    triggerHidden: function () {
      // var self = this;
      // if ($("#excluirModal").length) {
      //     $("#excluirModal").on("hidden.bs.modal", function () {
      //         self.$root.modal.excluir.isOpen = false;
      //     });
      // }
    },
    close(update) {
      if (!update) {
        update = false;
      }
      // $("#excluirModal").modal("hide");
      // $('.modal-backdrop').hide();
      this.$emit("close", update);
    },

    excluir() {
      this.erros = [];

      this.service
        .delete(this.model.id)
        .then((response) => {
          this.$notify({
            type: "success",
            message: "Excluído com sucesso",
            horizontalAlign: "center",
          });
          this.close(true);
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message:
              "Houve um erro ao tentar excluir, por favor recarregue a pagina",
            horizontalAlign: "center",
          });
          // let errors = err.response.data.errors;
          // console.log(errors);
          // this.erros = errors;
        });
    },
  },
};
</script>
