<template>
  <div
  
  :class="[
  { 'input-group': hasIcon },
  { 'form-group' : formGroup},
      // {'has-danger': errors.length},
      { focused: focused },
      { 'has-label': label || $slots.label },
      ]"
      >
      <slot name="label">
        <label
        v-if="label"
        class="form-control-label w-100"
        :class="labelClasses"
        >
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend" @click="$emit('btn')">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">
      <multiselect
      v-model="model"
      :options="options"
      :track-by="optionsKey"
      :label="optionsValue"
      v-on="listeners"
      v-bind="$attrs"     
      :selectLabel="selectLabel"
      :selectedLabel="selectedLabel"
      :deselectLabel="deselectLabel"
      :placeholder="placeholder"
      :class="[
      { 'is-valid': valid === true },
      { 'is-invalid': valid === false },
      inputClasses,
      ]"
      >
      <template slot="beforeList">
        <template>
          <li
          class=""
          v-if="options.length > 1 && Array.isArray(model) && model.length != options.length"
          @click="addAll()"
          >
          <span
          data-deselect="Deselecionar"
          class="multiselect__option btn-info"
          ><span>ADICIONAR TODAS AS OPÇÕES</span></span
          >
        </li>
        <li
        class=""
        v-if="Array.isArray(model) && model.length > 1 && options.length > 0"
        @click="removeAll()"
        >
        <span
        data-deselect="Deselecionar"
        class="multiselect__option btn-danger"
        ><span>REMOVER TODAS AS OPÇÕES</span></span
        >
      </li>
    </template>
  </template>
  <template slot="noOptions">No results found</template>
  <template slot="noResult">No results found</template>
</multiselect>
</slot>
<div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
  <span class="input-group-text">
    <slot name="addonRight">
      <i :class="addonRightIcon"></i>
    </slot>
  </span>
</div>
<slot name="infoBlock"></slot>
<slot name="helpBlock">
      <!--   <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="error">
      {{ error }}
    </div> -->
    <div
    class="text-danger invalid-feedback"
    style="display: block"
    :class="{ 'mt-2': hasIcon }"
    v-for="erro in errors"
    v-if="errors"
    >
    {{ erro }}
  </div>
</slot>
</div>
</template>
<script>
  import { ref } from "vue";
  import Multiselect from "vue-multiselect";
  export default {
    inheritAttrs: false,
    name: "multiple-select",
    components: { Multiselect },
    props: {
      required: {
        type: Boolean,
        description: "Whether input is required (adds an asterix *)",
      },
    // valid: {
    //   type: Boolean,
    //   description: "Whether is valid",
    //   default: undefined
    // },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    placeholder: {
      type: String,
      default: null,
      description: "default option",
    },
    selectLabel:{
      default:'Selecionar'
    },
    selectedLabel:{
      default:'Selecionado'
    },
    deselectLabel:{
      default:'Deselecionar'
    },
    formGroup:{
      default:true
    },
    // error: {
    //   type: String,
    //   description: "Input error (below input)"
    // },
    errors: {
      type: Array,
      default: () => [],
      description: "Input error (below input)",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    value: {
      type: [String, Number, Array],
      description: "Input value",
    },
    options: {
      type: Array,
      description: "options value",
    },
    optionsKey: {
      type: String,
      default: "id",
      description: "key name",
    },
    optionsValue: {
      type: String,
      default: "name",
      description: "value name",
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon",
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon",
    },
  },

  data() {
    return {
      model: [],
      focused: false,
    };
  },
  computed: {
    valid() {
      return this.errors.length;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
        );
    },
  },
  watch: {
    options: {
      // the callback will be called immediately after the start of the observation
      immediate: false,
      handler(val, oldVal) {
        this.init();
      },
    },
    value: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        this.init();
      },
    },
  },
  methods: {
    init() {
      if (this.value){
        if(Array.isArray(this.value)){
         return this.model = this.options.filter((opt) => this.value.includes(opt[this.optionsKey]));  
       }
       return this.model = this.options.find((opt) => this.value == opt[this.optionsKey]);        
     }
   },
   addAll() {
    this.model = [...this.options];
    this.updateValue(this.model);
  },
  removeAll() {
    this.model = [];
    this.updateValue(this.model);
  },
  updateValue(evt) {
      // console.log('updated');
      console.log(evt,evt[this.optionsKey]);
      let value;
      if(Array.isArray(this.value)){
        value = evt.map((values) => values[this.optionsKey]);
      }else{
       value = evt[this.optionsKey];
     }
     this.model = evt;
     this.$emit("input", value);
   },
   onFocus(value) {
    this.focused = true;
    this.$emit("focus", value);
  },
  onBlur(value) {
    this.focused = false;
    this.$emit("blur", value);
  },
},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.multiselect__tags {
  max-height: 50px;
  overflow-x: scroll;
  overflow-x: hidden;
  /*height: calc(1.5em + 1.25rem + 5px);*/

}

.input-group .form-control:not(:first-child) {
  /* border-left: 0; */
  /* padding-left: 0; */
  /* margin: 0; */
  padding: 0;

}

.input-group .form-control:not(:first-child) .multiselect__tags {
 border-left: 0; 
 padding-left: 0; 
 /* margin: 0; */
 height: calc(1.5em + 1.25rem + 5px);
 padding: 11px 40px 0 8px;
 /*padding: 0;*/

}
.input-group .form-control:not(:first-child) .multiselect__select {

  padding: 12px 8px 0 8px;
  /*padding: 0;*/

}
.multiselect__single{
  color:#8898aa;
}

</style>
