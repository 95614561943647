<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5"> </base-header>
    <div class="container-fluid mt-5">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                List <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
            <div class="col text-right">
              <base-button type="info" icon size="sm" @click="cleanFiltros()">
                <i class="fas fa-filter mr-2"></i>
                <span class="btn-inner--text">Clear filters</span>
              </base-button>
              <base-button
                type="primary"
                icon
                size="sm"
                @click="$router.push('/portos/adicionar')"
              >
                <i class="fas fa-plus mr-2"></i>
                <span class="btn-inner--text">New port</span>
              </base-button>
            </div>
          </div>
          <!-- pesquisa -->
          <div class="row align-items-center mt-3">
            <div class="col-lg-3">
              <base-input
                alternative=""
                label="Port"
                placeholder="search by port"
                input-classes="form-control-alternative"
                v-model.lazy="search.name"
              />
            </div>
            <!-- <div class="col-lg-2">
        <base-input
        alternative=""
        label="Port code"
        placeholder="search by port code"
        input-classes="form-control-alternative"
        v-model.lazy="search.sigla"

        />
      </div> -->
            <div class="col-lg-3">
              <base-input
                alternative=""
                label="Terminal"
                placeholder="search by terminal"
                input-classes="form-control-alternative"
                v-model.lazy="search.terminal"
              />
            </div>
            <div class="col-lg-3">
              <base-input
                alternative=""
                label="Berth"
                placeholder="search by berth"
                input-classes="form-control-alternative"
                v-model.lazy="search.berco"
              />
            </div>

            <div class="col-lg-1">
              <div class="form-group has-label">
                <label class="form-control-label" style="color: transparent">
                  search</label
                >
                <button
                  type="submit"
                  class="btn base-button btn-primary w-100"
                  :disabled="loading"
                  @click="listar()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <!-- <button type="submit" class="btn base-button btn-primary w-100" @click="submit()"><i class="fa fa-check"></i></button> -->
            </div>
          </div>
          <!-- pesquisa -->
        </div>

        <div class="table-responsive" style="overflow: visible">
          <table
            class="table align-items-center table-flush tablesorter"
            tbody-classes="list"
            :data="list"
          >
            <thead class="thead-light">
              <tr>
                <th>Port</th>
                <!-- <th>Acronym</th> -->
                <th>Order</th>
                <th class="text-center">Terminals</th>
                <th>Created in</th>

                <th></th>
              </tr>
            </thead>

            <tbody class="list">
              <tr v-for="(row, index) in list" :key="list.id">
                <td>
                  {{ row.name }}
                </td>
                <!-- <td>
          {{ row.sigla }}
        </td> -->
                <td>
                  {{ row.ordem }}
                </td>
                <td>
                  <b-card v-for="terminal in row.terminais" :key="terminal.id">
                    <button
                      class="btn btn-primary p-1 w-100"
                      v-b-toggle="'terminal-' + terminal.id"
                    >
                      {{ terminal.name }}
                    </button>

                    <b-collapse
                      :id="'terminal-' + terminal.id"
                      class="mt-2"
                      v-if="terminal.bercos.length"
                    >
                      <div
                        class="badge badge-primary text-sm"
                        v-for="berco in terminal.bercos"
                      >
                        {{ berco.name }}
                      </div>
                    </b-collapse>
                  </b-card>
                </td>
                <td>
                  {{ row.created_at | date }}
                </td>
                <!--     <td>
            <a style="cursor: pointer" @click="onProFeature" class="mr-2">
              <i class="fas fa-user-edit"></i>
            </a>
            <a style="cursor: pointer" @click="$root.openExcluirModal(
              row,
              'Você gostaria de excluir o usuário '+ row.name +'?',
              Service)">
              <i class="fas fa-trash"></i>
            </a>
          </td> -->
                <td class="text-right">
                  <base-dropdown class="dropdown" position="right">
                    <a
                      slot="title"
                      class="btn btn-sm btn-icon-only text-light"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-ellipsis-v"></i>
                    </a>

                    <template>
                      <router-link
                        class="dropdown-item"
                        :to="'/portos/editar/' + row.id"
                        ><i class="fas fa-edit"></i> edit</router-link
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="
                          $root.openExcluirModal(
                            row,
                            'Would you like to delete the port ' +
                              row.name +
                              '?',
                            Service
                          )
                        "
                        ><i class="fas fa-trash" style="min-width: 20px"></i>
                        delete</a
                      >
                    </template>
                  </base-dropdown>
                </td>
              </tr>
              <tr v-if="!list.length && !loading">
                <td colspan="100" style="text-align: center">No port found</td>
              </tr>
              <tr v-if="loading">
                <td colspan="100" style="text-align: center">
                  <i
                    class="fa fa-spinner fa-spin"
                    v-if="loading"
                    style="font-size: 20px"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer d-flex justify-content-end">
          <base-pagination
            :pagination="pagination"
            @change="listar()"
            align="center"
          ></base-pagination>
        </div>
      </card>
    </div>
    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
  </div>
</template>
<script>
import Service from "@/services/Portos";

export default {
  data() {
    return {
      Service,
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1,
      },
      search: {
        name: "",
        sigla: "",
        terminal: "",
        berco: "",
      },
      defaultSearch: {},
      orderBy: {
        campo: "name",
        ordem: "asc",
      },
      orderBy: {
        campo: "name",
        ordem: "asc",
      },
      list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
      ],
    };
  },
  created() {
    this.defaultSearch = { ...this.search };
    this.listar();
  },
  watch: {
    // search: {
    //   deep: true,
    //   handler() {
    //     this.pagination.page = 1;
    //     this.listar();
    //   },
    // },
    orderBy: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.listar();
      },
    },
  },
  methods: {
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false;
      if (updated) this.listar();
    },
    cleanFiltros() {
      this.search = { ...this.defaultSearch };
      this.listar();
    },
    listar() {
      this.loading = true;
      this.list = [];
      Service.get({
        with: ["terminais.bercos"],
        ...this.pagination,
        ...this.search,
      }).then((response) => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style></style>
