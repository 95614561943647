<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5" />
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Email information</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent="handleSubmit">
                <div class="col-lg-12">
                  <div class="form-group has-label">
                    <label class="form-control-label">
                      Email body
                      <i
                        class="fa fa-spinner fa-spin"
                        v-if="form.processing"
                      ></i>
                    </label>
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      v-model="form.description"
                    ></ckeditor>
                  </div>
                </div>
                <button
                  :disabled="form.processing"
                  type="submit"
                  class="btn base-button btn-primary float-right"
                >
                  <i class="fa fa-spinner fa-spin" v-if="form.processing"></i>
                  <i v-else>Save</i>
                </button>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Service from "@/services/Email";

export default {
  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      heigth: 600,
    },
    form: {
      description: "",
      errors: {},
      processing: false,
    },
  }),
  mounted() {
    this.find();
  },
  methods: {
    async find() {
      this.form.processing = true;
      const { data } = await Service.get();
      this.form = { ...this.form, ...data };
      this.form.processing = false;
    },
    async handleSubmit() {
      this.form.processing = true;
      await Service.submit(this.form);
      this.form.processing = false;
    },
  },
};
</script>
<style>
.ck-editor__editable_inline {
  min-height: 300px;
}
</style>
